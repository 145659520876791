.message-card-viewed {
  opacity: 0.5;
}

.condo-card.message-card {
  height: fit-content;
}

.message-card .message-card-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.message-card .message-card-footer {
  float: right;
}