.messages-counter .ant-badge-count {
  top: 1px;
  right: 1px;
  line-height: 18px;
  box-shadow: none;
}

.messages-counter .ant-badge-count .ant-scroll-number-only {
  transition: none;
}

.messages-counter.animating .messages-counter-icon {
  animation: shake-bell 0.05s;
}

.messages-counter.animating .ant-badge-count {
  animation: scale-badge 0.05s;
}

@keyframes shake-bell {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes scale-badge {
  0% {
    transform: scale(1) translate(50%, -50%);
  }

  50% {
    transform: scale(1.2) translate(50%, -50%);
  }

  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
