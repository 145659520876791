.user-messages-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 300px;
  max-height: 550px;
  padding: 20px;
  overflow-y: auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: var(--condo-global-box-shadow-default);
  scrollbar-width: none;
  overscroll-behavior: contain;
}

.user-messages-list .user-messages-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.user-messages-list-settings-icon {
  color: var(--condo-global-color-gray-7);
  cursor: pointer;
  transition: var(--condo-global-transition-duration-default);
}

.user-messages-list-settings-icon:hover,
.user-messages-list-settings-icon:active {
  color: var(--condo-global-color-black);
}

.user-messages-list-mobile-overlay.condo-dropdown {
  width: 100%;
}

.user-messages-list-mobile-overlay .user-messages-list {
  width: calc(100vw - 32px);
  height: calc(100vh - 40px - 32px);
  max-height: unset;
  margin: 16px auto auto;
}